import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import { Link, navigate } from 'gatsby';

import withLocation from '../hoc/withLocation';
import { login, loginWithLoginToken } from '../lib/api';
import { setLoginToken, setSessionId } from '../lib/storage';
import { userLoggedIn } from '../redux/actions';

import LoginAndRedirect from '../components/login-with-secret/LoginAndRedirect';
import { Base, Jumbo } from '../components/common';
import SEO from '../components/seo';

import tr from '../../translations';

import './login.css';
import lylliIcon from '../images/lylli-icon.png';
import showPasswordIcon from '../images/show-icon.png';
import hidePasswordIcon from '../images/hide-icon.png';


class LoginPage extends React.Component {
  state = {
    showPassword: false,
    error: null,
    email: '',
    password: '',
  };

  handleSubmit = async (e) => {
    const { actionUserLoggedIn } = this.props;
    const { email, password } = this.state;

    e.preventDefault();
    e.stopPropagation();

    try {
      // To disable router - fix the line below
      if (true) {
        // localStorage.getItem('testRedirect')) {
        const { _id: loginToken } = await loginWithLoginToken(email, password);
        const { sessionId } = await login({ username: email, password });
        setLoginToken(loginToken);
        setSessionId(sessionId);
        window.location.href = `https://www.lylli.se/router?lt=${loginToken}&region=SWE&origin=LOGIN`;
      }

      const { sessionId, userData } = await login({ username: email, password });
      actionUserLoggedIn(sessionId, userData);

      await navigate('/profile');
    } catch (ex) {
      if (ex.statusCode === 401) {
        this.setState({ error: tr('login.submit-error') });
      } else {
        this.setState({ error: ex.message });
      }
    }

    return false;
  };

  handleEmailChanged = ({ target: { value } }) => {
    this.setState({ email: value });
  };

  handlePasswordChanged = ({ target: { value } }) => {
    this.setState({ password: value });
  };

  render() {
    const { error, email, password } = this.state;

    const { secret, target } = this.props.search;
    if (secret) {
      return <LoginAndRedirect {...{ secret, target }} />;
    }

    return (
      <Base>
        <SEO title={tr('head.title')} />
        <Jumbo className="login" bgColor="#d1b7e4">
          <Row>
            <Col>
              <div className="login-icon-wrapper">
                <img className="login-icon" src={lylliIcon} alt="" />
              </div>
              <h1>{tr('login.title')}</h1>
              <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>{tr('login.email-label')}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={tr('login.email-placeholder')}
                    value={email}
                    onChange={this.handleEmailChanged}
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>{tr('login.password-label')}</Form.Label>
                  <Form.Control
                    type={this.state.showPassword ? 'text' : 'password'}
                    placeholder={tr('login.password-placeholder')}
                    isInvalid={!!error}
                    value={password}
                    onChange={this.handlePasswordChanged}
                  />
                  <div
                    className="show-hide-password-container"
                    onClick={this.toggleShowPassword}
                    aria-hidden="true"
                  >
                    <img
                      id="login-showPassword"
                      src={showPasswordIcon}
                      alt="visa"
                      onClick={() => this.setState({ showPassword: false })}
                      role="button"
                      style={this.state.showPassword ? { display: 'block' } : { display: 'none' }}
                    />
                    <img
                      id="login-hidePassword"
                      src={hidePasswordIcon}
                      alt="dölj"
                      onClick={() => this.setState({ showPassword: true })}
                      role="button"
                      style={this.state.showPassword ? { display: 'none' } : { display: 'block' }}
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
                </Form.Group>

                <button className="login-submit-btn" type="submit">
                  {tr('login.submit')}
                </button>

                <Row className="text-center">
                  <Col className="login-link">
                    <a href={tr('link.createAccount')}>{tr('login.createAccount')}</a>
                  </Col>
                  <Col className="login-link">
                    <Link to="/reset-password">{tr('login.resetPassword')}</Link>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Jumbo>
      </Base>
    );
  }
}

export default withLocation(
  connect(({ user }) => ({ user }), { actionUserLoggedIn: userLoggedIn })(LoginPage)
);
